import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import callApi from '../services/api';
import {
  colors, fontSizes, mobileThresholdPixels, StepContainer, Title2, Note, Button,
} from './home/v3/styledComponents';

const Text = styled.div`
  font-family: Roboto;
  color: ${colors.navy};
  line-height: 28px;
  font-size: ${fontSizes.m};
  margin-top: 20px;
  text-align: center;
  max-width: 764px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 25px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 140px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 35px;
  }
`;

const StyledNote = styled(Note)`
  color: ${colors.lightGrey4};
`;

class AdditionalSlots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'ready',
      slots: props.slots,
    };
    this.submit = this.submit.bind(this);
  }

  setIsSelected(index, isSelected) {
    const { slots } = this.state;
    slots[index].isSelected = isSelected;
    this.setState({ slots });
  }

  submit() {
    const { slots } = this.state;
    const { orderId } = this.props;
    const additionalSlots = slots
      .filter(slot => slot.isSelected)
      .map(slot => slot.date);
    this.setState({ status: 'loading' });
    callApi(`/public/acceptAdditionalSlots/${orderId}`, 'post', { additionalSlots })
      .then(() => {
        this.setState({ status: 'success' });
      })
      .catch(() => {
        this.setState({ status: 'error' });
      });
  }

  render() {
    const { slots, status } = this.state;

    return (
      <StepContainer>
        <Title2>Sélectionnez vos autres disponibilités</Title2>
        <Text>
          Afin de vous proposer un rendez-vous le plus rapidement possible nous avons demandé
          à nos Tillistes s’ils sont également disponibles proche des créneaux que vous avez
          déjà renseignés. Voici les différents créneaux sur lesquels ils peuvent s’occuper de vous :)
        </Text>
        <Row>
          {slots.map((slot, index) => (
            <Button
              key={slot.text}
              navy={slot.isSelected}
              onClick={() => this.setIsSelected(index, !slot.isSelected)}
            >{slot.text}</Button>
          ))}
        </Row>
        {status === 'success' &&
          <Text>
            Merci, ces créneaux ont bien été pris en compte. On revient vers vous rapidement :)
          </Text>
        }
        {status === 'error' &&
          <Text>
            {'Oups, quelque chose s\'est mal passé'}
          </Text>
        }
        {status === 'loading' &&
          <Button navy>Chargement</Button>
        }
        {status === 'ready' &&
          <Button
            navy
            onClick={this.submit}
          >Confirmer</Button>
        }
        <StyledNote>
          Nous vous tiendrons informé par mail lorsque votre Tilliste aura validé le rendez-vous !
        </StyledNote>
      </StepContainer>
    );
  }
}

AdditionalSlots.propTypes = {
  slots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderId: PropTypes.string.isRequired,
};

export default AdditionalSlots;
